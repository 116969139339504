var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "referral-main my-5 my-md-6" },
    [
      _c(
        "b-row",
        { attrs: { "align-h": "center" } },
        [
          _c("b-col", { staticClass: "col-auto" }, [
            _c("h3", { staticClass: "display-4 text-primary heading" }, [
              _vm._v("Refer a Friend"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-alert",
                {
                  attrs: {
                    variant: "warning",
                    show: _vm.alert.show,
                    dismissible: "",
                  },
                },
                [_vm._v(_vm._s(_vm.alert.message))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { attrs: { "align-h": "center", "align-v": "center" } },
        [
          _c(
            "b-col",
            { staticClass: "mb-5 mx-4 mx-md-0 mb-md-0", attrs: { md: "6" } },
            [
              _c("b-img", {
                attrs: {
                  fluid: "",
                  src: require("@/assets/images/trio-photo.png"),
                  alt: "A photo of three women",
                },
              }),
            ],
            1
          ),
          _c("b-col", { staticClass: "px-5" }, [
            _c(
              "h3",
              {
                staticClass: "display-3 heading referral-text",
                staticStyle: { "font-weight": "initial" },
              },
              [_vm._v(" Your friends are our friends. ")]
            ),
            _c("p", { staticClass: "sub-text" }, [
              _vm._v(
                " Loving your treatment? Share the news with your friends. We'll give them $50 off to support their well-being — and as a thank you, you'll get $25, too. "
              ),
            ]),
            _c(
              "div",
              [
                _c("span", { staticClass: "text-muted" }, [
                  _vm._v("Share your link and start sharing with friends: "),
                ]),
                _c("b-form-input", {
                  staticClass: "mt-3",
                  attrs: { placeholder: "Enter your friend's email" },
                  model: {
                    value: _vm.email,
                    callback: function ($$v) {
                      _vm.email = $$v
                    },
                    expression: "email",
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass: "text-small pt-2",
                    class:
                      _vm.alertType == "success"
                        ? "text-success"
                        : "text-danger",
                    staticStyle: { height: "20px" },
                  },
                  [_vm._v(" " + _vm._s(_vm.alertText) + " ")]
                ),
                _c("br"),
                _c(
                  "b-button",
                  {
                    staticClass: "mr-4 px-5",
                    attrs: { variant: "info", pill: "", size: "lg" },
                    on: { click: _vm.sendEmail },
                  },
                  [_vm._v("Start Sharing")]
                ),
                _c(
                  "CopyToClipboard",
                  {
                    attrs: { text: _vm.referralLink },
                    on: { copy: _vm.showCopyText },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "px-5",
                        attrs: {
                          variant: "outline-info",
                          pill: "",
                          size: "lg",
                        },
                      },
                      [_vm._v("Copy My Link")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }