<template>
  <b-container class="referral-main my-5 my-md-6">
    <b-row align-h="center">
      <b-col class="col-auto">
        <h3 class="display-4 text-primary heading">Refer a Friend</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-alert variant="warning" :show="alert.show" dismissible>{{
          alert.message
        }}</b-alert>
      </b-col>
    </b-row>
    <b-row align-h="center" align-v="center">
      <b-col md="6" class="mb-5 mx-4 mx-md-0 mb-md-0">
        <b-img
          fluid
          :src="require('@/assets/images/trio-photo.png')"
          alt="A photo of three women"
        />
      </b-col>
      <b-col class="px-5">
        <h3
          class="display-3 heading referral-text"
          style="font-weight: initial"
        >
          Your friends are our friends.
        </h3>
        <p class="sub-text">
          Loving your treatment? Share the news with your friends. We'll give
          them $50 off to support their well-being — and as a thank you, you'll get $25, too.
        </p>
        <div>
          <span class="text-muted"
            >Share your link and start sharing with friends:
          </span>
          <!-- <b-form-input v-model="referralLink" readonly /> -->
          <b-form-input
            v-model="email"
            placeholder="Enter your friend's email"
            class="mt-3"
          ></b-form-input>
          <div
            class="text-small pt-2"
            :class="alertType == 'success' ? 'text-success' : 'text-danger'"
            style="height: 20px"
          >
            {{ alertText }}
          </div>
          <br />
          <b-button
            variant="info"
            pill
            size="lg"
            class="mr-4 px-5"
            @click="sendEmail"
            >Start Sharing</b-button
          >
          <CopyToClipboard :text="referralLink" @copy="showCopyText">
            <b-button variant="outline-info" class="px-5" pill size="lg"
              >Copy My Link</b-button
            >
          </CopyToClipboard>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import CopyToClipboard from "vue-copy-to-clipboard";
import { UsersService } from "@/services/users.service";
import { ReferralService } from "@/services/referral.service";
import LocalStorageService from "@/localStorage";
const localStorageService = LocalStorageService.getService();

export default {
  components: {
    CopyToClipboard,
  },
  data() {
    return {
      referralLink: "",
      alertText: "",
      alertType: "",
      email: "",
      alert: {
        show: false,
        message: "",
      },
    };
  },
  watch: {
    email(val) {
      if (val.length == 0) {
        this.alertText = "";
        this.alertType = "";
      }
    },
  },
  async mounted() {
    window.analytics.page();
    const { data } = await UsersService.getReferralToken();
    this.referralLink = `${process.env.VUE_APP_SITE_URL}/referral?refcode=${data.referralcode}`;
    if (this.$route.params.refcode) {
      if (this.$route.params.refcode == data.referralcode) {
        this.showAlert("You can't use your own referral link.");
      } else {
        this.showAlert(
          "You've already created an account, and can't use the link."
        );
      }
    }
  },
  methods: {
    showAlert(message) {
      this.alert.show = true;
      this.alert.message = message;
    },
    showCopyText() {
      this.alertType = "success";
      this.alertText = "Link copied to clipboard!";
    },
    testEmail(email) {
      const reg =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s`'|@"]+(\.[^<>()\[\]\\.,;:\s@`'"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      return email == "" ? null : reg.test(email) ? true : false;
    },
    async sendEmail() {
      this.alertText = "";
      this.alertType = "";
      if (!this.email || !this.testEmail(this.email)) {
        this.alertText = "Please enter a valid email";
        return;
      }
      let listOfEmails = localStorageService.getItem("referralsSent");
      // return;
      if (listOfEmails) {
        listOfEmails = listOfEmails.split(",");
        if (listOfEmails.includes(this.email)) {
          this.alertText = "You already sent an email to this friend!";
          return;
        }
      }
      try {
        const { data } = await ReferralService.sendReferralEmail(
          this.referralLink,
          this.email
        );
        if (data) {
          this.alertType = "success";
          this.alertText = "Thank you! Email sent!";
          if (!listOfEmails) {
            localStorageService.setItem("referralsSent", `${this.email},`);
          } else {
            listOfEmails.push(this.email);
            localStorageService.setItem("referralsSent", listOfEmails);
          }
        }
      } catch (err) {
        this.alertType = "error";
        switch (err.message) {
          case "User already exist":
          case "User already started onboarding. Please copy the link and share to them instead":
          case "User already exist as lead. Please copy link and share to them instead":
          case "User already exist as lead":
            this.alertType = "success";
            this.alertText = "Thank you! Email sent!";
            break;
          case "You cannot refer yourself.":
            this.alertText = "You can't refer yourself!";
            break;
          default:
            this.alertText = err;
            break;
        }
      }
    },
  },
};
</script>

<style>
.referral-text {
  line-height: 1.5;
}
</style>
